// React
import React from "react";
//import PropTypes from 'prop-types';

// Material ui
import {makeStyles} from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: 90,
    marginBottom: 70,
    padding: 15,
    maxWidth: 800,
    marginLeft: "auto",
    marginRight: "auto",
  },
  mainHeaderText: {
    marginBottom: 40,
  },
  headerText: {
    marginTop: 20,
    marginBottom: 20,
  },
}));

export default function TermsOfService() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      {/*className={classes.mainHeaderText}*/}
      <Typography variant="h3" component="h1" gutterBottom>
        Maxer - Terms of Service
      </Typography>

      <Typography variant="h6" gutterBottom>
        1. Introduction
      </Typography>

      <Typography gutterBottom>
        Welcome to Maxer BV (“Company”, “we”, “our”, “us”). These Terms of
        Service (“Terms”, “Terms of Service”) govern your use of our web pages
        located at https://www.maxer.io operated by Maxer BV.
      </Typography>

      <ul>
        <li>
          <Typography>To facilitate our Service;</Typography>
        </li>
        <li>
          <Typography>To provide the Service on our behalf;</Typography>
        </li>
        <li>
          <Typography>To perform Service-related services; or</Typography>
        </li>
        <li>
          <Typography>
            To assist us in analyzing how our Service is used.
          </Typography>
        </li>
      </ul>
    </div>
  );
}

// TermsOfService.propTypes = {
//   // theme: PropTypes.object,
//   // classes: PropTypes.object,
// };
